const DefaultEnum = Object.freeze({
    MANUAL: "manual",
    PLACA: "placa",
    SIM: true,
    NAO: false,
    PIX: "pix",
    CARTAO_CREDITO: "cartao_credito",
    BOLETO: "boleto",
    CARTAO_SUCESSO: "succeeded",
    CARTAO_PAGO: "Pago",
    FISICA: "fisica",
    JURIDICA: "juridica"
});

export default DefaultEnum;